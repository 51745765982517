import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Column, DataGrid, Export, HeaderFilter, MasterDetail, Paging } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import { alert } from 'devextreme/ui/dialog';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { selectFiltroOs } from '../../../../redux/slices/consignadoComodato/filtroOsSlice';
import { ImprimirEmLoteComodato } from '../../../../services/Relatorios/ImprimirEmLoteComodato';
import { SessionManager } from '../../../../shared/utils/sessionManager';
import { VALUES_OS } from '../../consignado-comodato-nova-os/ValuesOsKey';
import { ApiHistoricoDePedidosService } from '../../consignado-comodato-nova-os/usercases/apiHistoricoDePedidos.service';
import { ApiRecuperarOsService } from './../usercases/ApiRecuperarOs.service';
import { CellSaldo } from './CellSaldo';
import { CellStatus } from './CellStatus';
import styles from './Component.module.scss';
import { OsDetailGrid } from './OsDatail/OsDatail';
import { ExportarListaOsParaExcelService } from './ExportarListaOsParaExcell.service';
import PropTypes from 'prop-types';

export function ListaOs({ setSearchingMessage, setSearching, headerFilter }) {
  const { values } = useSelector(selectFiltroOs);
  const [resolution, setResolution] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const updateColumns = () => {
      setResolution(window.innerWidth);
    };
    window.addEventListener('resize', updateColumns);
    updateColumns();
    return () => window.removeEventListener('resize', updateColumns);
  }, []);

  const onExporting = useCallback(
    async e => {
      await new ExportarListaOsParaExcelService().execute(e, 'Relatório de Pedido', headerFilter.current.filtroString(), 'Relatorio_Pedido_Comodato', worksheet => {
        worksheet.getColumn(14).numFmt = '"R$" #,##0.00';
        worksheet.getColumn(15).numFmt = '"R$" #,##0.00';
        worksheet.getColumn(1).style = { font: { bold: false, size: 8 }, column: { width: 20 } };
        worksheet.getColumn(10).style = { font: { bold: false, size: 8 }, column: { width: 20 } };
        worksheet.getColumn(11).style = { font: { bold: false, size: 8 }, column: { width: 800 } };
      });

      e.cancel = true;
    },
    [headerFilter],
  );

  const onRowClick = useCallback(
    async e => {
      try {
        setSearchingMessage('Carregando Pedido...');
        setSearching(true);
        const os = await new ApiRecuperarOsService().execute(e.row.data.id);
        if (!os) {
          alert('Não foi possível recuperar o Pedido.', 'Atenção!');
          setSearching(false);
          return;
        }
        const hist = await new ApiHistoricoDePedidosService().execute(os.cliente.id);
        os.historicoDePedidos = hist;
        new SessionManager().saveEncryptedData(VALUES_OS, os);
        setSearching(false);
        history.push('/operacoes/pedidos/novo');
      } catch (error) {
        setSearching(false);
        Swal.fire('Erro', error.message, 'error');
      }
    },
    [setSearching, setSearchingMessage, history],
  );

  const detailRender = useCallback(props => {
    const expanded = !props.value;
    const detailClick = () => {
      if (props.value) {
        props.component.collapseRow(props.key);
      } else {
        props.component.expandRow(props.key);
      }
    };

    return <span onClick={detailClick}>{expanded ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>;
  }, []);

  const onClickImpressaoLote = useCallback(
    async e => {
      try {
        setSearchingMessage('Gerando Impressão...');
        setSearching(true);
        const download = new ImprimirEmLoteComodato();
        await download.execute(e.row.data.uId, e.row.data.numeroPedido);
        setSearching(false);
      } catch (error) {
        setSearching(false);
        Swal.fire('Erro', error.message, 'error');
      }
    },
    [setSearching, setSearchingMessage],
  );

  return (
    <div className={`${styles.listaOs} content-wrapper`} style={{ position: 'relative', top: '-20px' }}>
      <DataGrid
        rowAlternationEnabled={true}
        dataSource={(values || []).itens}
        noDataText="Sem dados"
        keyExpr="id"
        id="dataGrid"
        allowColumnReordering={true}
        remoteOperations={true}
        onExporting={onExporting}
        className={`${styles.middle}`}
      >
        <MasterDetail enabled={true} component={OsDetailGrid} />
        <Column type="detailExpand" cellRender={detailRender} />
        <Column dataField="status" width={50} caption="Status" cellRender={CellStatus} className={` p-0 m-0`}>
          <HeaderFilter groupInterval={10000} />
        </Column>
        <Column dataField="numeroPedido" width={60} caption="Pedido"></Column>
        <Column dataField="nFes" minWidth={80} visible={resolution > 1000} caption={'NF-es'}></Column>
        <Column dataField="vTexId" caption="VTEX" width={130} visible={resolution > 1250}></Column>
        <Column dataField="origem" width={60} visible={resolution > 800} caption="Origem"></Column>
        <Column dataField="nomeCliente" caption="Cliente" minWidth={200}></Column>
        <Column dataField="telefoneCliente" caption="Telefone" width={100}></Column>
        <Column dataField="logradouro" minWidth={200} caption="Endereço Entrega" dataType="text" visible={resolution > 1250}></Column>
        <Column dataField="bairro" visible={resolution > 1100} caption="Bairro" dataType="text"></Column>
        <Column dataField="municipio" caption="Cidade/UF" dataType="text" visible={resolution > 1100}></Column>
        <Column dataField="cep" caption="CEP" dataType="text" visible={resolution > 1100}></Column>
        <Column dataField="enderecoRecolha" allowExporting={true} visible={false} caption="Endereço Entrega" dataType="text"></Column>
        <Column dataField="enderecoRecolhaBairro" allowExporting={true} visible={false} caption="Endereço Recolha Bairro" dataType="text"></Column>
        <Column dataField="enderecoRecolhaMunicipioUf" allowExporting={true} visible={false} caption="Endereço Recolha Municipio/UF" dataType="text"></Column>
        <Column dataField="enderecoRecolhaCep" allowExporting={true} visible={false} caption="Endereço Recolha CEP" dataType="text"></Column>
        <Column dataField="dataPedido" dataType="date" width={100} format={'dd/MM/yyyy HH:mm'}></Column>
        <Column dataField="dataEvento" visible={resolution > 1250} dataType="date" width={100} format={'dd/MM/yyyy HH:mm'}></Column>
        <Column dataField="dataEntregaRetirada" visible={resolution > 1100} caption="Ent./Ret." dataType="date" format={'dd/MM/yyyy'} />
        <Column dataField="dataRecolhaDevolucao" visible={resolution > 1100} dataType="date" format={'dd/MM/yyyy'} />
        <Column caption={'Saldo'} visible={resolution > 850} dataField="saldo" alignment={'right'} cellRender={CellSaldo} />
        <Column dataField="totalGeral" caption={'Total Geral'} format="R$ #,##0.00" />
        <Column type="buttons" width={60} caption="Ações">
          <Button hint="Editar Pedido" icon="edit" onClick={onRowClick} />
          <Button hint="Imprimir Tudo" icon="print" onClick={onClickImpressaoLote} />
        </Column>
        <Paging defaultPageSize={20} defaultPageIndex={0} /> {/* Shows the second page */}
        <Export
          enabled={true}
          texts={{ exportAll: 'Exporte tudo para {0}', exportSelectedRows: 'Exporte a seleção para {0}', exportTo: 'Exporte para {0}' }}
          formats={['xlsx']}
          keepColumnWidths={true}
          fileName={'Ralatório de Pedido Comodato'}
        />
      </DataGrid>
    </div>
  );
}

ListaOs.propTypes = {
  setSearchingMessage: PropTypes.func.isRequired,
  setSearching: PropTypes.func.isRequired,
  headerFilter: PropTypes.object.isRequired,
}
