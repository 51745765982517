import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import moment from 'moment';
import saveAs from 'file-saver';
import { getDadosLogin, maskedCnpj } from '../../../../shared/utils/Utils';

export class ExportarListaOsParaExcelService {
  async execute(e, title, filters, fileName, configure = null) {
    const dadosLogin = getDadosLogin();
    const empresa = dadosLogin.empresas.find(x => x.cnpj === dadosLogin.cnpj);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(title);

    if (configure) configure(worksheet);

    return exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: {
        row: 4,
        column: 1,
      },
    })
      .then(async cellRange => {
        // Procurar a coluna "Bairro" para encontrar o índice
        let bairroColIndex;
        const headerDataRow = worksheet.getRow(4); // A linha 4 contém os cabeçalhos das colunas
        headerDataRow.eachCell((cell, colNumber) => {
          if (cell.value === 'Bairro') {
            // Encontrar a célula com o cabeçalho "Bairro"
            bairroColIndex = colNumber;
          }
        });

        if (bairroColIndex) {
          const enderecoRecolhaColIndex = bairroColIndex + 1; // Somar mais 1 para colocar "Endereço de Entrega" após "Bairro"
          const enderecoRecolhaBaiColIndex = enderecoRecolhaColIndex + 1; // Somar mais 1 para colocar "Endereço de Entrega" após "Bairro"
          const enderecoRecolhaMunColIndex = enderecoRecolhaBaiColIndex + 1; // Somar mais 1 para colocar "Endereço de Entrega" após "Bairro"
          const enderecoRecolhaCepColIndex = enderecoRecolhaMunColIndex + 1; // Somar mais 1 para colocar "Endereço de Entrega" após "Bairro"

          // Inserir a nova coluna manualmente
          worksheet.getRow(4).getCell(enderecoRecolhaColIndex).value = 'Endereço de Recolha'; // Define o cabeçalho da coluna
          worksheet.getRow(4).getCell(enderecoRecolhaBaiColIndex).value = 'Bairro de Recolha'; // Define o cabeçalho da coluna
          worksheet.getRow(4).getCell(enderecoRecolhaMunColIndex).value = 'Município/UF de Recolha'; // Define o cabeçalho da coluna
          worksheet.getRow(4).getCell(enderecoRecolhaCepColIndex).value = 'Cep de Recolha'; // Define o cabeçalho da coluna

          // Ajustar a largura da coluna "Endereço de Entrega"
          worksheet.getColumn(enderecoRecolhaColIndex).width = 40; // Define a largura desejada (ajuste conforme necessário)
          worksheet.getColumn(enderecoRecolhaBaiColIndex).width = 20; // Define a largura desejada (ajuste conforme necessário)
          worksheet.getColumn(enderecoRecolhaMunColIndex).width = 15; // Define a largura desejada (ajuste conforme necessário)
          worksheet.getColumn(enderecoRecolhaCepColIndex).width = 10; // Define a largura desejada (ajuste conforme necessário)

          // Copiar o estilo da coluna "Bairro" e aplicar à coluna "Endereço de Entrega"
          const bairroHeaderCellStyle = worksheet.getRow(4).getCell(bairroColIndex).style; // Estilo da célula do cabeçalho "Bairro"

          // Aplicando o estilo à nova coluna "Endereço de Entrega"
          worksheet.getRow(4).getCell(enderecoRecolhaColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(enderecoRecolhaBaiColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(enderecoRecolhaMunColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(enderecoRecolhaCepColIndex).style = bairroHeaderCellStyle;

          // const data = e.component.getDataSource().items(); // Obter os dados da fonte diretamente
          const dataSource = e.component.getDataSource();
          const data = await dataSource.store().load();
          data.forEach((item, index) => {
            const rowIndex = index + 5; // Começar na linha 5 (logo após o cabeçalho)
            const enderecoRecCell = worksheet.getRow(rowIndex).getCell(enderecoRecolhaColIndex); // Obtendo a célula da nova coluna
            const enderecoBaiCell = worksheet.getRow(rowIndex).getCell(enderecoRecolhaBaiColIndex); // Obtendo a célula da nova coluna
            const enderecoMunCell = worksheet.getRow(rowIndex).getCell(enderecoRecolhaMunColIndex); // Obtendo a célula da nova coluna
            const enderecoCepCell = worksheet.getRow(rowIndex).getCell(enderecoRecolhaCepColIndex); // Obtendo a célula da nova coluna

            // Preencher a célula com o valor de "Endereço de Entrega"
            enderecoRecCell.value = item.enderecoRecolha || '';
            enderecoBaiCell.value = item.enderecoRecolhaBairro || '';
            enderecoMunCell.value = item.enderecoRecolhaMunicipioUf || '';
            enderecoCepCell.value = item.enderecoRecolhaCep || '';

            // Copiar o estilo da célula "Bairro" para a célula "Endereço de Entrega"
            const bairroCellStyle = worksheet.getRow(rowIndex).getCell(bairroColIndex).style; // Estilo da célula "Bairro"
            enderecoRecCell.style = bairroCellStyle; // Aplicar o estilo
            enderecoBaiCell.style = bairroCellStyle; // Aplicar o estilo
            enderecoMunCell.style = bairroCellStyle; // Aplicar o estilo
            enderecoCepCell.style = bairroCellStyle; // Aplicar o estilo
          });
        }

        worksheet.mergeCells(1, 1, 1, worksheet.columnCount);
        worksheet.mergeCells(2, 1, 2, worksheet.columnCount);
        worksheet.mergeCells(3, 1, 3, worksheet.columnCount);

        //Header
        const headerRow = worksheet.getRow(1, 2);
        headerRow.getCell(2).value = title;
        headerRow.height = 25;
        headerRow.getCell(2).font = {
          size: 18,
        };
        headerRow.getCell(2).alignment = {
          horizontal: 'center',
        };

        //Subtitle
        const headerSubRow = worksheet.getRow(2, 2);
        headerSubRow.height = 25;
        headerSubRow.getCell(2).value = `CNPJ: ${maskedCnpj(empresa.cnpj)} - ${empresa.nomeFantasia}`;
        headerSubRow.getCell(2).font = {
          size: 14,
        };
        headerSubRow.getCell(2).alignment = {
          horizontal: 'center',
        };

        //Filters
        const filtersRow = worksheet.getRow(3, 2);
        filtersRow.height = 25;
        filtersRow.getCell(2).value = filters;
        filtersRow.getCell(2).font = {
          size: 14,
        };
        filtersRow.getCell(2).alignment = {
          horizontal: 'center',
        };

        // footer Time
        const footerRow = cellRange.to.row + 2;

        const footerTimeRow = worksheet.getRow(footerRow, 1);
        worksheet.mergeCells(footerRow, 1, footerRow, 3); // Mescla da coluna 1 até a coluna 3 na linha `footerRow`
        footerTimeRow.getCell(1).value = moment(new Date()).format('DD/MM/YYYY HH:mm');
        footerTimeRow.getCell(1).font = {
          italic: true,
        };

        // footer URL
        const footerUrlRow = worksheet.getRow(footerRow, 1);
        worksheet.mergeCells(footerRow, 4, footerRow, worksheet.columnCount - 1);
        footerUrlRow.getCell(4).value = dadosLogin.tipoSiteUrl;
        footerUrlRow.getCell(4).font = {
          italic: true,
        };
        footerUrlRow.getCell(4).alignment = {
          horizontal: 'center',
        };

        // footer User
        const footerUserRow = worksheet.getRow(footerRow, 1);
        footerUserRow.getCell(worksheet.columnCount).value = dadosLogin.nome;
        footerUserRow.getCell(worksheet.columnCount).font = {
          italic: true,
        };

        worksheet.autoFilter = {
          from: {
            row: 4,
            column: 1,
          },
          to: {
            row: cellRange.to.row,
            column: worksheet.columnCount,
          },
        };
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
        });
      });
  }
}
